import React, { lazy, Suspense } from 'react';

const LazyInvoice = lazy(() => import('./Invoice'));

const Invoice = (props) => (
  <Suspense fallback={null}>
    <LazyInvoice {...props} />
  </Suspense>
);

export default Invoice;
