import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import useUserApi from 'hooks/useUserApi';
import DefaultTemplate from 'templates/DefaultTemplate/DefaultTemplate';
import SpinnerComponent from 'components/SpinnerComponent/SpinnerComponent';
import spinnerSize1 from 'components/SpinnerComponent/SpinnerComponent.constants';
import UserDetailMetadata from './UserDetailMetadata/UserDetailMetadata';
import UserDetailTable from './UserDetailTable/UserDetailTable';

const UserDetailPage = () => {
  const { userId } = useParams();
  const { getUser, userData, errorGettingUser, loadingUser } = useUserApi();
  const [tableRowsData, setTableRowsData] = useState([]);
  const [userMetadata, setUserMetadata] = useState({});
  const loading = loadingUser;

  // Get user data on mount
  useEffect(() => {
    getUser(userId);
  }, [userId, getUser]);

  useEffect(() => {
    if (userData) {
      setUserMetadata(userData);
      setTableRowsData(userData.groups);
    }
  }, [userData]);

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: User Detail {userId}</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">User Detail</h1>
              {/* <HelpIcon link="user-details" id="userDetail" /> */}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={5} className="my-4">
            {userMetadata && <UserDetailMetadata userMetadata={userMetadata} setUserMetadata={setUserMetadata} />}
          </Col>
        </Row>
        <Row>
          <Col>
            {errorGettingUser && <p>There was an issue loading the user data.</p>}
            {(loading || !userData) && (
              <div className="mt-3">
                <SpinnerComponent size={spinnerSize1} setFullVh={false} />
              </div>
            )}
            {tableRowsData.length === 0 && !loading && <p>There are no users for the user.</p>}
            {tableRowsData.length !== 0 && !loading && <UserDetailTable rowsData={tableRowsData} />}
          </Col>
        </Row>
      </Container>
    </DefaultTemplate>
  );
};

export default UserDetailPage;
