import React from 'react';
import PropTypes from 'prop-types';

import UserDetailMetadataTable from './UserDetailMetadataTable/UserDetailMetadataTable';

const UserDetailMetadata = ({ userMetadata }) => {
  return <UserDetailMetadataTable userMetadata={userMetadata} />;
};

UserDetailMetadata.defaultProps = {
  userMetadata: null,
};

UserDetailMetadata.propTypes = {
  userMetadata: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default UserDetailMetadata;
