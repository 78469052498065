import { string, object } from 'yup';

const WIPCreateSchema = object().shape({
  wipName: string()
    .required({
      field: 'wipName',
      errorMessage: 'Required field.',
    })
    .matches(/^[=\s\]\x5B0-9a-z!#$%&'()+,-]+$/, {
      excludeEmptyString: true,
      message: {
        field: 'wipName',
        errorMessage: "Only lowercase letters, numbers, spaces, and !#$%&'()+,-=[]",
      },
    }),
  cluster: object().shape({
    label: string().required({
      field: 'cluster',
      errorMessage: 'Required field.',
    }),
  }),
});

export default WIPCreateSchema;
