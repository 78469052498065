import React, { lazy, Suspense } from 'react';

const LazyAssemblySearch = lazy(() => import('./AssemblySearch'));

const AssemblySearch = (props) => (
  <Suspense fallback={null}>
    <LazyAssemblySearch {...props} />
  </Suspense>
);

export default AssemblySearch;
