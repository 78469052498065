import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';

const HelpIcon = ({ link, id }) => {
  return (
    <>
      <Link to={`/help#${link}`} id={id}>
        <AiOutlineQuestionCircle data-testid="help-icon" />
      </Link>

      <UncontrolledTooltip placement="right" target={id}>
        Help for section
      </UncontrolledTooltip>
    </>
  );
};

HelpIcon.propTypes = {
  link: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default HelpIcon;
