import {
  SET_USER,
  SET_PERMISSION_TYPES,
  SET_SELECT_FIELD_OPTIONS,
  SET_ASSET_SEARCH_FORM_DATA,
  SET_ASSEMBLY_SEARCH_FORM_DATA,
  SET_PLANNING_SEARCH_FORM_DATA,
  SET_VENDOR_SEARCH_FORM_DATA,
  SET_INVOICE_SEARCH_FORM_DATA,
  SET_CONTRACT_SEARCH_FORM_DATA,
  SET_ASSET_SUBJECT_FORM_DATA,
  SET_LOADING,
  SET_FILE_FORMAT_TYPES,
  SET_API_IS_BROKEN,
  TOGGLE_SHOW_MORE_ASSET_SEARCH_OPTIONS,
  TOGGLE_SHOW_MORE_PLANNING_SEARCH_OPTIONS,
  SET_AUTHENTICATING_WITH_MODAL,
  SET_ASSET_SEARCH_TABLE_CONFIG_PAGE_INDEX,
  SET_ASSET_SEARCH_TABLE_CONFIG_PAGE_SIZE,
  SET_PLANNING_SEARCH_TABLE_CONFIG_PAGE_INDEX,
  SET_PLANNING_SEARCH_TABLE_CONFIG_PAGE_SIZE,
  SET_RENDITION_TABLE_CONFIG_PAGE_INDEX,
  SET_RENDITION_TABLE_CONFIG_PAGE_SIZE,
  SET_ACTIVE_TAB,
} from 'store/action-types';
import { ASSET_SEARCH_FORM_INITIAL_STATE } from 'store/form-data';

export const setUser = (user) => ({ user, type: SET_USER });

export const setPermissionTypes = (permissionTypes) => ({ permissionTypes, type: SET_PERMISSION_TYPES });

export const setAssetSearchForm = (assetSearchFormData) => ({
  assetSearchFormData,
  type: SET_ASSET_SEARCH_FORM_DATA,
});

export const setVendorIdAsAssetFormData = (vendorId) => ({
  assetSearchFormData: {
    ...ASSET_SEARCH_FORM_INITIAL_STATE,
    vendorId,
  },
  type: SET_ASSET_SEARCH_FORM_DATA,
});

export const setAssemblySearchFormData = (assemblySearchFormData) => ({
  assemblySearchFormData,
  type: SET_ASSEMBLY_SEARCH_FORM_DATA,
});

export const setPlanningSearchFormData = (planningSearchFormData) => ({
  planningSearchFormData,
  type: SET_PLANNING_SEARCH_FORM_DATA,
});

export const setVendorSearchFormData = (vendorSearchFormData) => ({
  vendorSearchFormData,
  type: SET_VENDOR_SEARCH_FORM_DATA,
});

export const setInvoiceSearchFormData = (invoiceSearchFormData) => ({
  invoiceSearchFormData,
  type: SET_INVOICE_SEARCH_FORM_DATA,
});

export const setContractSearchFormData = (contractSearchFormData) => ({
  contractSearchFormData,
  type: SET_CONTRACT_SEARCH_FORM_DATA,
});

export const setAssetSubjectFormData = (assetSubjectFormData) => ({
  assetSubjectFormData,
  type: SET_ASSET_SUBJECT_FORM_DATA,
});

export const setSelectFieldOptions = (pluralSelector, options) => ({
  type: SET_SELECT_FIELD_OPTIONS,
  pluralSelector,
  options,
});

export const setLoading = (loading) => ({
  loading,
  type: SET_LOADING,
});

export const setFileFormatTypes = (fileFormatTypes) => ({
  fileFormatTypes,
  type: SET_FILE_FORMAT_TYPES,
});

export const setApiIsBroken = (apiIsBroken) => ({
  apiIsBroken,
  type: SET_API_IS_BROKEN,
});

export const toggleShowMoreAssetSearchOptions = () => ({
  type: TOGGLE_SHOW_MORE_ASSET_SEARCH_OPTIONS,
});

export const toggleShowMorePlanningSearchOptions = () => ({
  type: TOGGLE_SHOW_MORE_PLANNING_SEARCH_OPTIONS,
});

export const setAssetSearchTableConfigPageIndex = (assetSearchTablePageIndex) => ({
  assetSearchTablePageIndex,
  type: SET_ASSET_SEARCH_TABLE_CONFIG_PAGE_INDEX,
});

export const setAssetSearchTableConfigPageSize = (assetSearchTablePageSize) => ({
  assetSearchTablePageSize,
  type: SET_ASSET_SEARCH_TABLE_CONFIG_PAGE_SIZE,
});

export const setPlanningSearchTableConfigPageIndex = (planningSearchTablePageIndex) => ({
  planningSearchTablePageIndex,
  type: SET_PLANNING_SEARCH_TABLE_CONFIG_PAGE_INDEX,
});

export const setPlanningSearchTableConfigPageSize = (planningSearchTablePageSize) => ({
  planningSearchTablePageSize,
  type: SET_PLANNING_SEARCH_TABLE_CONFIG_PAGE_SIZE,
});

export const setRenditionTableConfigPageIndex = (renditionTablePageIndex) => ({
  renditionTablePageIndex,
  type: SET_RENDITION_TABLE_CONFIG_PAGE_INDEX,
});

export const setRenditionTableConfigPageSize = (renditionTablePageSize) => ({
  renditionTablePageSize,
  type: SET_RENDITION_TABLE_CONFIG_PAGE_SIZE,
});

export const setAuthenticatingWithModal = (authenticatingWithModal) => ({
  authenticatingWithModal,
  type: SET_AUTHENTICATING_WITH_MODAL,
});

export const setActiveTab = (activeTab) => ({
  activeTab,
  type: SET_ACTIVE_TAB,
});
