import React, { lazy, Suspense } from 'react';

const LazyUserLogin = lazy(() => import('./UserLogin'));

const UserLogin = (props) => (
  <Suspense fallback={null}>
    <LazyUserLogin {...props} />
  </Suspense>
);

export default UserLogin;
