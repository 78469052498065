import React, { lazy, Suspense } from 'react';

const LazyInvoiceSearch = lazy(() => import('./InvoiceSearch'));

const InvoiceSearch = (props) => (
  <Suspense fallback={null}>
    <LazyInvoiceSearch {...props} />
  </Suspense>
);

export default InvoiceSearch;
