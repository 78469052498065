import React, { lazy, Suspense } from 'react';

const LazyContractSearch = lazy(() => import('./ContractSearch'));

const ContractSearch = (props) => (
  <Suspense fallback={null}>
    <LazyContractSearch {...props} />
  </Suspense>
);

export default ContractSearch;
