import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setAuthenticatingWithModal } from 'store/action-creators';

const useUserLoginModal = () => {
  const authenticatingWithModal = useSelector((state) => state.authenticatingWithModal);
  const dispatch = useDispatch();

  const openAuthModal = useCallback(() => {
    dispatch(setAuthenticatingWithModal(true));
  }, [dispatch]);

  const closeAuthModal = useCallback(() => {
    dispatch(setAuthenticatingWithModal(false));
  }, [dispatch]);

  return {
    authenticatingWithModal,
    openAuthModal,
    closeAuthModal,
  };
};

export default useUserLoginModal;
