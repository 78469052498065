import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';

import { Routes } from 'shared/constants/constants';
import UserLogin from 'pages/UserLogin/UserLogin.lazy';
import AssetSearch from 'pages/AssetSearch/AssetSearch.lazy';
import AssetCreate from 'pages/AssetCreate/AssetCreate.lazy';
import Asset from 'pages/Asset/Asset.lazy';
import AssetSubject from 'pages/AssetSubject/AssetSubject.lazy';
import AssemblySearch from 'pages/AssemblySearch/AssemblySearch.lazy';
import Assembly from 'pages/Assembly/Assembly.lazy';
import Planning from 'pages/Planning/Planning.lazy';
import Rendition from 'pages/Rendition/Rendition.lazy';
import RenditionCreate from 'pages/RenditionCreate/RenditionCreate.lazy';
import PlanningSearch from 'pages/PlanningSearch/PlanningSearch.lazy';
import PlanningCreate from 'pages/PlanningCreate/PlanningCreate.lazy';
import VendorSearch from 'pages/VendorSearch/VendorSearch.lazy';
import VendorCreate from 'pages/VendorCreate/VendorCreate.lazy';
import Vendor from 'pages/Vendor/Vendor.lazy';
import Contract from 'pages/Contract/Contract.lazy';
import ContractSearch from 'pages/ContractSearch/ContractSearch.lazy';
import ContractCreate from 'pages/ContractCreate/ContractCreate.lazy';
import InvoiceSearch from 'pages/InvoiceSearch/InvoiceSearch.lazy';
import InvoiceCreate from 'pages/InvoiceCreate/InvoiceCreate.lazy';
import Invoice from 'pages/Invoice/Invoice.lazy';
import Reports from 'pages/Reports/Reports';
import HelpPage from 'pages/Help/Help';
import WIPCreate from 'pages/WIPCreate/WIPCreate';
import UserDetailPage from 'pages/UserDetail/UserDetailPage';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import useAuthApi from './hooks/useAuthApi';

const AppRoutes = () => {
  const history = useHistory();
  const location = useLocation();
  const { checkIfUserIsAuthenticated, userDataFromStore } = useAuthApi();

  // Scroll to top on route change
  useEffect(() => history.listen(() => window.scrollTo(0, 0)), [history]);

  // Check if user is authorized on mount and route change.
  useEffect(() => {
    checkIfUserIsAuthenticated();
  }, [checkIfUserIsAuthenticated, location.pathname]);

  return (
    <Switch>
      {/* userDataFromStore = undefined -> not sure if user is authenticated or validated (confirm ticket from cookie is already set and valid) */}
      {/* userDataFromStore = null -> user is not authenticated or validated */}
      {/* userDataFromStore is assigned a user -> user is authenticated or validated */}

      {/* When userDataFromStore equals undefined we need to authenticate the user */}
      {/* if no auth cookies are present or validate when the cookies are present. */}
      {/* Either way this means getting the user data. */}
      {/* We do not want to show the routes until we know we have user data in the store. */}
      {/* While userDataFromStore is undefined we know that the user is being authenticated or validated. */}
      {/* And not until userDataFromStore is null (unauthenticated) or defined (authenticated) */}
      {/* do we return the routes. */}

      {/* Login Page */}
      {/* Is authorized to unauthenticated users only. */}
      <ProtectedRoute
        exact
        redirectTo={location.state?.from ?? Routes.ASSET_SEARCH.toLink()}
        captureFromState={false}
        path={Routes.LOGIN.path}
        isAuthorized={!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <UserLogin />
      </ProtectedRoute>

      {/* Asset Pages */}

      {/* Asset Search Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.ASSET_SEARCH.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <AssetSearch />
      </ProtectedRoute>

      {/* Asset Create Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.ASSET_CREATE.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <AssetCreate />
      </ProtectedRoute>

      {/* Asset Detail Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.ASSET.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <Asset />
      </ProtectedRoute>

      {/* Asset Subject Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.ASSET_SUBJECT.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <AssetSubject />
      </ProtectedRoute>

      {/* Assembly Pages */}

      {/* Assembly Search Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.ASSEMBLY_SEARCH.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <AssemblySearch />
      </ProtectedRoute>

      {/* Assembly Detail Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.ASSEMBLY.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <Assembly />
      </ProtectedRoute>

      {/* Rendition Pages */}

      {/* Rendition Create Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.RENDITION_CREATE.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <RenditionCreate />
      </ProtectedRoute>

      {/* Rendition Detail Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.RENDITION.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <Rendition />
      </ProtectedRoute>

      {/* Planning Pages */}

      {/* Planning Search Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.PLANNING_SEARCH.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <PlanningSearch />
      </ProtectedRoute>

      {/* Planning Create Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.PLANNING_CREATE.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <PlanningCreate />
      </ProtectedRoute>

      {/* Planning Detail Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.PLANNING.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <Planning />
      </ProtectedRoute>

      {/* Vendor Pages */}

      {/* Vendor Search Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.VENDOR_SEARCH.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <VendorSearch />
      </ProtectedRoute>

      {/* Vendor Create Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.VENDOR_CREATE.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <VendorCreate />
      </ProtectedRoute>

      {/* Vendor Detail Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.VENDOR.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <Vendor />
      </ProtectedRoute>

      {/* Contract Pages */}

      {/* Contract Search Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.CONTRACT_SEARCH.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <ContractSearch />
      </ProtectedRoute>

      {/* Contract Create Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.CONTRACT_CREATE.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <ContractCreate />
      </ProtectedRoute>

      {/* Contract Detail Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.CONTRACT.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <Contract />
      </ProtectedRoute>

      {/* Invoice Pages */}

      {/* Invoice Search Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.INVOICE_SEARCH.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <InvoiceSearch />
      </ProtectedRoute>

      {/* Invoice Create Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.INVOICE_CREATE.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <InvoiceCreate />
      </ProtectedRoute>

      {/* Invoice Detail Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.INVOICE.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <Invoice />
      </ProtectedRoute>

      {/* Reports Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.REPORTS.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <Reports />
      </ProtectedRoute>

      {/* Assembly Create Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.ASSEMBLY_CREATE.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <WIPCreate />
      </ProtectedRoute>

      {/* Help Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.HELP.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <HelpPage />
      </ProtectedRoute>

      {/* User Detail Page */}
      {/* Is authorized to authenticated users only. */}
      <ProtectedRoute
        exact
        path={Routes.USER_DETAIL.path}
        isAuthorized={!!userDataFromStore}
        isAuthorizing={userDataFromStore === undefined}
      >
        <UserDetailPage />
      </ProtectedRoute>

      {/*
        Redirect back to asset search page if no routes above have matched.
      */}
      <Route path="*">
        <Redirect to={Routes.ASSET_SEARCH.toLink()} />
      </Route>
    </Switch>
  );
};

export default AppRoutes;
