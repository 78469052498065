import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { composeWithDevTools } from 'redux-devtools-extension';

import {
  ASSET_SEARCH_FORM_INITIAL_STATE,
  PLANNING_SEARCH_FORM_INITIAL_STATE,
  VENDOR_SEARCH_FORM_INITIAL_STATE,
  INVOICE_SEARCH_FORM_INITIAL_STATE,
  CONTRACT_SEARCH_FORM_INITIAL_STATE,
  ASSET_SUBJECT_FORM_INITIAL_STATE,
  ASSEMBLY_SEARCH_FORM_INITIAL_STATE,
} from 'store/form-data';
import { DEFAULT_TABLE_PAGE_INDEX, DEFAULT_TABLE_PAGE_SIZE } from 'shared/constants/constants';
import reducer from './reducer';

export const initialState = {
  assetSearchFormData: ASSET_SEARCH_FORM_INITIAL_STATE,
  assemblySearchFormData: ASSEMBLY_SEARCH_FORM_INITIAL_STATE,
  planningSearchFormData: PLANNING_SEARCH_FORM_INITIAL_STATE,
  vendorSearchFormData: VENDOR_SEARCH_FORM_INITIAL_STATE,
  invoiceSearchFormData: INVOICE_SEARCH_FORM_INITIAL_STATE,
  contractSearchFormData: CONTRACT_SEARCH_FORM_INITIAL_STATE,
  assetSubjectFormData: ASSET_SUBJECT_FORM_INITIAL_STATE,
  showMoreAssetSearchOptions: false,
  showMorePlanningSearchOptions: false,
  assetSearchTablePageSize: DEFAULT_TABLE_PAGE_SIZE,
  assetSearchTablePageIndex: DEFAULT_TABLE_PAGE_INDEX,
  planningSearchTablePageSize: DEFAULT_TABLE_PAGE_SIZE,
  planningSearchTablePageIndex: DEFAULT_TABLE_PAGE_INDEX,
  renditionTablePageSize: DEFAULT_TABLE_PAGE_SIZE,
  renditionTablePageIndex: DEFAULT_TABLE_PAGE_INDEX,
  user: undefined,
  authenticatingWithModal: false,
  activeTab: '1',
};

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    'user',
    'assetSearchTablePageSize',
    'planningSearchTablePageSize',
    'assetSearchFormData',
    'assemblySearchFormData',
    'planningSearchFormData',
    'vendorSearchFormData',
    'invoiceSearchFormData',
    'contractSearchFormData',
    'assetSubjectFormData',
    'activeTab',
  ],
};
const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(persistedReducer, initialState, composeWithDevTools());
export const persistor = persistStore(store);
