import React, { lazy, Suspense } from 'react';

const LazyRenditionCreate = lazy(() => import('./RenditionCreate'));

const RenditionCreate = (props) => (
  <Suspense fallback={null}>
    <LazyRenditionCreate {...props} />
  </Suspense>
);

export default RenditionCreate;
