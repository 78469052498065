export const WIP_CREATE_FORM_INITIAL_STATE = {
  wipName: '',
  project: 'Assembly',
  cluster: '',
  processKey: 'ebAssembly',
  wfType: 'editing',
};

// Select field custom styles
export const CreateWIPSelectCustomStyles = {
  control: {
    minWidth: '20rem',
    maxWidth: '20rem',
    margin: '0rem',
  },
  menu: {
    minWidth: '20rem',
    maxWidth: '20rem',
  },
};
