import React, { useState } from 'react';
import { NavLink as RRDNavLink } from 'react-router-dom';
import { ENDPOINTS, Routes } from 'shared/constants/constants';
import {
  Collapse,
  Navbar,
  Button,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { MdMenu, MdExitToApp, MdPersonOutline } from 'react-icons/md';
import { RiLockPasswordLine } from 'react-icons/ri';

import './Header.scss';
import useAuthApi from 'hooks/useAuthApi';
import ChangePasswordModal from 'components/ChangePasswordModal/ChangePasswordModal';
import beamLogo from './beam_logo_dark_150.png';

const Header = () => {
  const { signOutUser, signingOut, userDataFromStore: user } = useAuthApi();
  const [isOpen, setIsOpen] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const userId = String(user?.userId);

  const handleLogoutClick = () => {
    signOutUser();
  };

  return (
    <>
      <header className="header" data-testid="header">
        <Navbar
          color="primary"
          expand="xs"
          className="firstnav py-0 d-flex justify-content-between align-items-center w-100"
        >
          <Collapse navbar>
            <Nav navbar>
              <NavItem className="d-none d-md-flex align-items-center">
                <img className="navbar-brand" src={beamLogo} alt="link to beam" height="30" />
              </NavItem>
              <NavItem>
                <a href={ENDPOINTS.AM_API} target="_self" className="nav-link">
                  <span className="d-none d-sm-block">Article Management</span>
                  <span className="d-block d-sm-none">AM</span>
                </a>
              </NavItem>
              <NavItem>
                <NavLink activeClassName="active" to="/" tag={RRDNavLink}>
                  <span className="d-none d-sm-block mb-2">Media Asset Management</span>
                  <span className="d-block d-sm-none mb-2">MAM</span>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar className="ms-md-auto pe-50">
                <DropdownToggle nav caret className="bold">
                  Profile
                </DropdownToggle>

                <DropdownMenu end>
                  <DropdownItem className="w-100 d-flex align-items-center justify-content-start text-primary">
                    <NavLink
                      className="text-primary p-0"
                      activeClassName="active"
                      to={Routes.USER_DETAIL.toLink({ userId })}
                      tag={RRDNavLink}
                    >
                      <MdPersonOutline size={18} className="me-2" />
                      {user?.name}
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem
                    className="d-flex align-items-center justify-content-start text-primary"
                    onClick={() => setShowChangePasswordModal(true)}
                  >
                    <RiLockPasswordLine size={18} className="me-2" />
                    Change Password
                  </DropdownItem>
                  <DropdownItem
                    disabled={signingOut}
                    className="d-flex align-items-center justify-content-start text-primary"
                    onClick={handleLogoutClick}
                  >
                    <MdExitToApp size={18} className="me-2" /> {signingOut ? 'Signing out...' : 'Sign out'}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
          <Button color="primary" className="d-md d-md-none p-0" onClick={() => setIsOpen(!isOpen)}>
            <MdMenu size={32} />
          </Button>
        </Navbar>

        <Navbar light expand="md" className="secondnav p-0">
          <Collapse navbar>
            <Nav navbar className="menu">
              {/* Asset */}
              {user?.editRights ? (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Asset
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem>
                      <NavLink className="text-primary p-0" activeClassName="active" to="/" tag={RRDNavLink} exact>
                        Asset Search
                      </NavLink>
                    </DropdownItem>

                    <DropdownItem>
                      <NavLink
                        className="text-primary p-0"
                        activeClassName="active"
                        to="/asset/create"
                        tag={RRDNavLink}
                      >
                        Create New Asset
                      </NavLink>
                    </DropdownItem>

                    <DropdownItem>
                      <NavLink
                        className="text-primary p-0"
                        activeClassName="active"
                        to="/asset-subject"
                        tag={RRDNavLink}
                      >
                        Asset Subject
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : (
                <NavItem>
                  <NavLink activeClassName="active" to="/" tag={RRDNavLink}>
                    Asset Search
                  </NavLink>
                </NavItem>
              )}

              {/* Assembly */}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Assembly
                </DropdownToggle>

                <DropdownMenu>
                  <DropdownItem>
                    <NavLink
                      className="text-primary p-0"
                      activeClassName="active"
                      to="/assembly/search"
                      tag={RRDNavLink}
                    >
                      Assembly Search
                    </NavLink>
                  </DropdownItem>

                  <DropdownItem>
                    <NavLink className="text-primary p-0" activeClassName="active" to="/wip/create" tag={RRDNavLink}>
                      Create WIP
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              {/* Planning */}
              {user?.isRequestor ? (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Planning
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem>
                      <NavLink
                        className="text-primary p-0"
                        activeClassName="active"
                        to="/planning/search"
                        tag={RRDNavLink}
                      >
                        Planning Search
                      </NavLink>
                    </DropdownItem>

                    <DropdownItem>
                      <NavLink
                        className="text-primary p-0"
                        activeClassName="active"
                        to="/planning/create"
                        tag={RRDNavLink}
                      >
                        {user?.editRights ? 'Create New Planning' : 'Media Request'}
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : (
                <NavItem>
                  <NavLink activeClassName="active" to="/planning/search" tag={RRDNavLink}>
                    Planning Search
                  </NavLink>
                </NavItem>
              )}

              {/* Vendor */}
              {user?.editRights ? (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Vendor
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem>
                      <NavLink
                        className="text-primary p-0"
                        activeClassName="active"
                        to="/vendor/search"
                        tag={RRDNavLink}
                      >
                        Vendor Search
                      </NavLink>
                    </DropdownItem>

                    <DropdownItem>
                      <NavLink
                        className="text-primary p-0"
                        activeClassName="active"
                        to="/vendor/create"
                        tag={RRDNavLink}
                      >
                        Create New Vendor
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : (
                <NavItem>
                  <NavLink activeClassName="active" to="/vendor/search" tag={RRDNavLink}>
                    Vendor Search
                  </NavLink>
                </NavItem>
              )}

              {/* Contract */}
              {user?.editRights ? (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Contract
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem>
                      <NavLink
                        className="text-primary p-0"
                        activeClassName="active"
                        to="/contract/search"
                        tag={RRDNavLink}
                      >
                        Contract Search
                      </NavLink>
                    </DropdownItem>

                    <DropdownItem>
                      <NavLink
                        className="text-primary p-0"
                        activeClassName="active"
                        to="/contract/create"
                        tag={RRDNavLink}
                      >
                        Create New Contract
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : (
                <NavItem>
                  <NavLink activeClassName="active" to="/contract/search" tag={RRDNavLink}>
                    Contract Search
                  </NavLink>
                </NavItem>
              )}

              {/* Invoice */}
              {user?.editRights ? (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Invoice
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem>
                      <NavLink
                        className="text-primary p-0"
                        activeClassName="active"
                        to="/invoice/search"
                        tag={RRDNavLink}
                      >
                        Invoice Search
                      </NavLink>
                    </DropdownItem>

                    <DropdownItem>
                      <NavLink
                        className="text-primary p-0"
                        activeClassName="active"
                        to="/invoice/create"
                        tag={RRDNavLink}
                      >
                        Create New Invoice
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : (
                <NavItem>
                  <NavLink activeClassName="active" to="/invoice/search" tag={RRDNavLink}>
                    Invoice Search
                  </NavLink>
                </NavItem>
              )}

              <NavItem>
                <NavLink activeClassName="active" to="/reports" tag={RRDNavLink}>
                  Reports
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink activeClassName="active" to="/help" tag={RRDNavLink}>
                  Help
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </header>
      {showChangePasswordModal && <ChangePasswordModal toggle={() => setShowChangePasswordModal(false)} />}
    </>
  );
};

export default Header;
