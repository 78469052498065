import React, { lazy, Suspense } from 'react';

const LazyVendorSearch = lazy(() => import('./VendorSearch'));

const VendorSearch = (props) => (
  <Suspense fallback={null}>
    <LazyVendorSearch {...props} />
  </Suspense>
);

export default VendorSearch;
