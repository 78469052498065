/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';

import { ENDPOINTS } from 'shared/constants/constants';
import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import useHelpApi from '../../hooks/useHelpApi';
import styles from './Help.module.scss';

const Help = () => {
  const { getHelp, helpData, errorGettingHelp } = useHelpApi();
  const user = useSelector((state) => state.user);
  const { isAdmin, editRights } = user;
  const [pageMessage, setPageMessage] = useState('Loading help page');
  const userGuide = helpData
    ?.replaceAll('src="media', `src="${ENDPOINTS.EDITORIAL_API}/bsc/docs/media`)
    .replace('docs.css', `${ENDPOINTS.EDITORIAL_API}/bsc/docs/docs.css`);

  let userRights = '';

  if (isAdmin) {
    userRights = 'Administrator';
  } else if (editRights) {
    userRights = 'Editor';
  }

  useEffect(() => {
    if (errorGettingHelp) {
      setPageMessage(<p>Cannot load user guide</p>);
    }
  }, [errorGettingHelp]);

  // get help content on mount
  useEffect(() => {
    if (isAdmin) {
      getHelp('-admin');
    } else if (editRights) {
      getHelp('-editor');
    } else getHelp('');
  }, [editRights, getHelp, isAdmin]);

  return (
    <DefaultTemplate>
      <Helmet>
        <title>MAM: {userRights} User Guide</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">MAM: {userRights} User Guide</h1>
            </div>
          </Col>
        </Row>
        {!userGuide && pageMessage}

        <div className={styles.help}>
          <span dangerouslySetInnerHTML={{ __html: userGuide }} />
        </div>
      </Container>
    </DefaultTemplate>
  );
};

export default Help;
