import React, { lazy, Suspense } from 'react';

const LazyAssetSearch = lazy(() => import('./AssetSearch'));

const AssetSearch = (props) => (
  <Suspense fallback={null}>
    <LazyAssetSearch {...props} />
  </Suspense>
);

export default AssetSearch;
