import { useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';

import useFetchWithState from './useFetchWithState';
import useFetch from './useFetch';
import {
  ENDPOINTS,
  COOKIE_ATTRIBUTES,
  COOKIE_TICKET_NAME,
  COOKIE_USER_NAME,
  COOKIE_TICKET_ENCODED_NAME,
} from '../shared/constants/constants';
import { setUser } from '../store/action-creators';
import useUserLoginModal from './useUserLoginModal';

const { BESSIE_API, EDITORIAL_API } = ENDPOINTS;

const useAuthApi = () => {
  const setCookie = Cookies.set;
  const removeCookie = Cookies.remove;
  const getCookie = Cookies.get;
  // const { set as setCookie, remove as removeCookie, get as getCookie } = Cookies
  const {
    mamFetchWithState: signIn,
    data: signInData,
    error: errorSigningIn,
    loading: signingIn,
  } = useFetchWithState();
  const {
    mamFetchWithState: signOut,
    data: signOutData,
    error: errorSigningOut,
    loading: signingOut,
  } = useFetchWithState();
  const {
    mamFetchWithState: getUserData,
    data: userData,
    error: errorGettingUser,
    loading: gettingUser,
  } = useFetchWithState();
  const { mamFetch: validateTicketRequest } = useFetch();
  const dispatch = useDispatch();
  const userDataFromStore = useSelector((state) => state.user);
  const { closeAuthModal, openAuthModal } = useUserLoginModal();

  /**
   * @param  {JSON Object} requestBody - stringified data from user login form
   */
  const signInUser = (requestBody) => {
    signIn(`${EDITORIAL_API}/login/user`, {
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      method: 'POST',
      body: requestBody,
    });
  };

  /**
   * @param  {string} username - signed in user's username
   */
  const getUser = useCallback(
    (username) => {
      getUserData(`${BESSIE_API}/user/${username}`);
    },
    [getUserData]
  );

  // On a successful authentication store the Alfresco auth ticket and
  // other auth data in browser cookies and get the user data.
  useEffect(() => {
    if (signInData) {
      const { ticket, user, authorization } = signInData;

      setCookie(COOKIE_TICKET_NAME, ticket, COOKIE_ATTRIBUTES);
      setCookie(COOKIE_TICKET_ENCODED_NAME, authorization, COOKIE_ATTRIBUTES);
      setCookie(COOKIE_USER_NAME, user, COOKIE_ATTRIBUTES);

      getUser(user);
    }
  }, [signInData, getUser, setCookie]);

  const signOutUser = () => {
    const { ticketFromCookie: ticket } = getAuthCookies();
    const requestBody = JSON.stringify({ ticket });

    signOut(`${EDITORIAL_API}/logout/ticket`, {
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      method: 'POST',
      body: requestBody,
    });
  };

  // Clear out auth cookies
  const clearAuthCookies = useCallback(() => {
    removeCookie(COOKIE_TICKET_ENCODED_NAME, COOKIE_ATTRIBUTES);
    removeCookie(COOKIE_USER_NAME, COOKIE_ATTRIBUTES);
    removeCookie(COOKIE_TICKET_NAME, COOKIE_ATTRIBUTES);
  }, [removeCookie]);

  // Clear out auth cookies as well as user data from Redux store.
  const clearAuthCookiesAndUserReduxStoreData = useCallback(() => {
    clearAuthCookies();

    dispatch(setUser(null));
  }, [dispatch, clearAuthCookies]);

  // Get auth cookies
  const getAuthCookies = useCallback(
    () => ({
      encodedTicketFromCookie: getCookie(COOKIE_TICKET_ENCODED_NAME, COOKIE_ATTRIBUTES),
      usernameFromCookie: getCookie(COOKIE_USER_NAME, COOKIE_ATTRIBUTES),
      ticketFromCookie: getCookie(COOKIE_TICKET_NAME, COOKIE_ATTRIBUTES),
    }),
    [getCookie]
  );

  // If the user is successfully signed out remove the cookies
  // from the browser and clear the user data from Redux.
  // In order to be safe in the case of an error during sign out
  // we will perform the same steps.
  useEffect(() => {
    if (signOutData || errorSigningOut) {
      clearAuthCookiesAndUserReduxStoreData();
    }
  }, [signOutData, errorSigningOut, clearAuthCookiesAndUserReduxStoreData]);

  // Once the user data has been retrieved store
  // persist it in Redux.
  useEffect(() => {
    if (userData) {
      // TODO: Removed email, first name and last name. Should we keep?
      const { username, displayName, userId, groups } = userData;

      dispatch(
        setUser({
          name: username,
          displayName,
          isAdmin: !!groups.find((group) => [621, 658].includes(group.groupId)), // MAM_ADMIN, MAM_RIGHTS_ADMIN
          editRights: !!groups.find((group) => [622, 621, 658].includes(group.groupId)), // MAM_EDITOR, MAM_ADMIN, MAM_RIGHTS_ADMIN
          isRequestor: !!groups.find((group) => [656].includes(group.groupId)), // MAM_REQUESTOR
          userId,
        })
      );

      closeAuthModal();
    }
  }, [userData, dispatch, closeAuthModal]);

  // If there is an error getting the user
  // clear auth cookies and user data from Redux store.
  useEffect(() => {
    if (errorGettingUser) {
      clearAuthCookiesAndUserReduxStoreData();
    }
  }, [errorGettingUser, clearAuthCookiesAndUserReduxStoreData]);

  // Perform a check to make sure the user is authenticated.
  const checkIfUserIsAuthenticated = useCallback(
    async (usingModalForAuth = false) => {
      /**
       * @param  {string} ticket - Alfresco auth ticket from cookie
       */
      const validateTicket = async (ticket) => {
        const requestBody = JSON.stringify({ ticket });

        return validateTicketRequest(`${EDITORIAL_API}/login/ticket`, {
          headers: { 'Content-Type': 'application/json; charset=utf-8' },
          method: 'POST',
          body: requestBody,
        });
      };

      const { encodedTicketFromCookie, usernameFromCookie, ticketFromCookie } = getAuthCookies();

      if (encodedTicketFromCookie && usernameFromCookie && ticketFromCookie) {
        try {
          const validateTicketResponse = await validateTicket(ticketFromCookie);
          const ticketFromValidateTicketResponse = validateTicketResponse.ticket;

          if (!ticketFromValidateTicketResponse) {
            throw validateTicketResponse;
          }

          if (!userDataFromStore && !gettingUser) {
            getUser(usernameFromCookie);
          }

          return true;
        } catch {
          if (usingModalForAuth) {
            // This condition handles when we are using the login modal and not routing to the login
            // page. This happens when we want to finish performing an action.
            // For example, creating an asset. In this case we do not want to clear
            // the user from the Redux store because this will trigger our route
            // logic to redirect to the login page. In this case we
            // only clear the auth cookies.
            clearAuthCookies();
            openAuthModal();
          } else {
            clearAuthCookiesAndUserReduxStoreData();
          }

          return false;
        }
      }

      if (usingModalForAuth) {
        // This condition also handles when we are using the login modal
        // and not routing to the login page. See comment above.
        clearAuthCookies();
        openAuthModal();
      } else {
        clearAuthCookiesAndUserReduxStoreData();
      }

      return false;
    },
    [
      clearAuthCookiesAndUserReduxStoreData,
      getUser,
      userDataFromStore,
      validateTicketRequest,
      gettingUser,
      clearAuthCookies,
      getAuthCookies,
      openAuthModal,
    ]
  );

  return {
    signInUser,
    errorSigningIn,
    signingIn,
    signOutUser,
    signingOut,
    errorGettingUser,
    gettingUser,
    userDataFromStore,
    checkIfUserIsAuthenticated,
    getAuthCookies,
  };
};

export default useAuthApi;
