import React, { lazy, Suspense } from 'react';

const LazyInvoiceCreate = lazy(() => import('./InvoiceCreate'));

const InvoiceCreate = (props) => (
  <Suspense fallback={null}>
    <LazyInvoiceCreate {...props} />
  </Suspense>
);

export default InvoiceCreate;
