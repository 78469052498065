import generateRoutes from '../../utils/routing-utils';

export const MAM_ASSET_SEARCH_API_DATA = 'MAM_ASSET_SEARCH_API_DATA';
export const MAM_ASSET_SEARCH_FORM_DATA = 'MAM_ASSET_SEARCH_FORM_DATA';
export const MAM_ASSET_TYPES = 'MAM_ASSET_TYPES';
export const MAM_VENDORS = 'MAM_ASSET_VENDORS';
export const MAX_NUM_ASSETS = 1000;
export const API_MAX = 500;
export const API_MAX_PER_PAGE = 1000;
export const PERM_TYPE_IDS_WITHOUT_PERM_SUB_TYPES = [7, 8, 12];
export const PHOTO_TYPE_IDS = [1];
export const ART_AND_CART_TYPE_IDS = [2, 3, 4, 5, 6, 12, 16];
export const MULTIMEDIA_TYPE_IDS = [7, 8, 21];
export const INTERACTIVE_TYPE_IDS = [20];
export const ALL_STATUSES = [1, 7, 14, 8, 13, 12, 38];
export const MULTI_STATUSES = [21, 28, 29, 30, 39];
export const ART_CART_STATUSES = [2, 25, 21, 22, 15, 23, 24, 37];
export const PHOTO_STATUSES = [5, 6, 11, 21, 27];
export const INTERACTIVE_STATUSES = [2, 25, 21, 22, 15, 23, 24, 33, 34, 35, 36, 37, 38];
export const ACCEPTABLE_IMAGE_TYPES = ['Bitmap', 'GIF Image', 'JPEG Image', 'PNG Image', 'TIFF Image'];
export const UNACCEPTABLE_REND_TYPES = [1, 2, 3];
export const EDITORIAL_API = process.env.REACT_APP_EDITORIAL_API;
export const AM_BASE_URL = process.env.REACT_APP_AM_BASE_URL;
export const ELSIE_API = process.env.REACT_APP_ELSIE_API;

// Table config defaults
export const DEFAULT_TABLE_PAGE_SIZE = 10;
export const DEFAULT_TABLE_PAGE_INDEX = 0;

// Routes
export const Routes = generateRoutes({
  ASSET_CREATE: '/asset/create',
  ASSET: '/asset/:assetId',
  ASSET_SUBJECT: '/asset-subject',
  ASSEMBLY_SEARCH: '/assembly/search',
  ASSEMBLY: '/assembly/:assemblyId',
  PLANNING_SEARCH: '/planning/search',
  PLANNING_CREATE: '/planning/create',
  PLANNING: '/planning/:planningId',
  VENDOR_SEARCH: '/vendor/search',
  VENDOR_CREATE: '/vendor/create',
  VENDOR: '/vendor/:vendorId',
  CONTRACT_SEARCH: '/contract/search',
  CONTRACT_CREATE: '/contract/create',
  CONTRACT: '/contract/:contractId',
  INVOICE_SEARCH: '/invoice/search',
  INVOICE_CREATE: '/invoice/create',
  INVOICE: '/invoice/:invoiceId',
  RENDITION_CREATE: '/rendition/create/:assetId',
  RENDITION: '/rendition/:assetId/:renditionId',
  LOGIN: '/login',
  ASSET_SEARCH: '/asset/search',
  REPORTS: '/reports',
  ASSEMBLY_CREATE: '/wip/create',
  HELP: '/help',
  USER_DETAIL: '/user/:userId',
});

// Auth cookies
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
export const COOKIE_EXPIRATION = 1;
export const COOKIE_TICKET_NAME = 'beamTicket';
export const COOKIE_TICKET_ENCODED_NAME = 'beamEncodedTicket';
export const COOKIE_USER_NAME = 'beamUser';
export const COOKIE_ATTRIBUTES = {
  expires: COOKIE_EXPIRATION,
  ...(COOKIE_DOMAIN && { domain: COOKIE_DOMAIN }),
};

export const INVALID_TICKET_REDIRECT_OPERATIONS = {
  HANDLE_ASSET_UPDATE: 'handleAssetUpdate',
  HANDLE_ASSET_CREATE: 'handleAssetCreate',
  HANDLE_ASSET_DELETE: 'handleAssetDelete',
  HANDLE_INVOICE_UPDATE: 'handleInvoiceUpdate',
  HANDLE_INVOICE_DELETE: 'handleInvoiceDelete',
  HANDLE_INVOICE_CREATE: 'handleInvoiceCreate',
  HANDLE_CONTRACT_UPDATE: 'handleContractUpdate',
  HANDLE_CONTRACT_DELETE: 'handleContractDelete',
  HANDLE_CONTRACT_CREATE: 'handleContractCreate',
  HANDLE_VENDOR_UPDATE: 'handleVendorUpdate',
  HANDLE_VENDOR_DELETE: 'handleVendorDelete',
  HANDLE_VENDOR_CREATE: 'handleVendorCreate',
  HANDLE_ASSET_SUBJECT_DELETE: 'handleAssetSubjectDelete',
  HANDLE_ASSET_SUBJECT_CREATE_AND_UPDATE: 'handleAssetSubjectCreateAndUpdate',
  HANDLE_RENDITION_UPDATE: 'handleRenditionUpdate',
  HANDLE_RENDITION_DELETE: 'handleRenditionDelete',
  HANDLE_RENDITION_CREATE: 'handleRenditionCreate',
  HANDLE_PLANNING_UPDATE: 'handlePlanningUpdate',
  HANDLE_PLANNING_DELETE: 'handlePlanningDelete',
  HANDLE_PLANNING_STATUS_UPDATE: 'handlePlanningStatusUpdate',
  HANDLE_PLANNING_CREATE: 'handlePlanningCreate',
};

export const ENDPOINTS = {
  BESSIE_API: process.env.REACT_APP_BESSIE_API,
  EDITORIAL_API: process.env.REACT_APP_EDITORIAL_API,
  AM_API: process.env.REACT_APP_AM_BASE_URL,
};

export const ASSET_DELIVERED_TYPES = [
  {
    id: 1,
    name: 'Online',
  },
  {
    id: 7,
    name: 'P2P',
  },
  {
    id: 4,
    name: 'Syndication - North America',
  },
  {
    id: 6,
    name: 'Syndication - International',
  },
  {
    id: 8,
    name: 'EB Affiliate',
  },
  {
    id: 5,
    name: 'Image Collection',
  },
  {
    id: 2,
    name: 'CD/DVD',
  },
  {
    id: 3,
    name: 'Print',
  },
];

const DURATIONS = [
  'Years',
  'Unlimited',
  'Life of Product',
  '15th Edition',
  '16th Edition',
  '17th Edition',
  'Current Edition',
];

export const DURATION_OPTIONS = DURATIONS.map((duration, id) => ({
  durationId: id + 1,
  name: duration,
}));

const arrayToSelectOptions = (arr = []) =>
  arr.map((item) => ({
    value: item,
    label: item,
  }));

export const COLOR_OPTIONS = arrayToSelectOptions([1, 2, 3, 4]);

const DISPOSITIONS = ['New', 'New License', 'Kill/Drop', 'Replace', 'Revise', 'Stet'];

export const DISPOSITION_OPTIONS = arrayToSelectOptions(DISPOSITIONS);

export const CODEC_TYPES = ['Animation', 'Cinepak', 'Graphics', 'Photo', 'Sorensen', 'Video', 'H.264', 'Simple'];
export const VIDEO_CODEC_OPTIONS = CODEC_TYPES.map((codec, id) => ({
  value: id + 1,
  label: codec,
}));

export const DISPOSITION_SELECT_OPTIONS = ['New', 'New License', 'Kill/Drop', 'Replace', 'Revise', 'Stet'];

export const PLANNING_PRIORITY_OPTIONS = [
  {
    label: 'Essential',
    value: 1,
  },
  {
    label: 'Important',
    value: 2,
  },
  {
    label: 'Normal',
    value: 3,
  },
];

export const PLANNING_EFFORT_OPTIONS = [
  {
    label: 'Low',
    value: 1,
  },
  {
    label: 'Moderate',
    value: 2,
  },
  {
    label: 'High',
    value: 3,
  },
];
