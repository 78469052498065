import { useCallback } from 'react';

import { ENDPOINTS } from 'shared/constants/constants';
import useFetchWithState from './useFetchWithState';

const useUserApi = () => {
  const {
    mamFetchWithState: getUserData,
    data: userData,
    error: errorGettingUser,
    loading: loadingUser,
  } = useFetchWithState();

  /**
   * @param  {string} userId - user ID
   */
  const getUser = useCallback(
    (userId) => {
      getUserData(`${ENDPOINTS.BESSIE_API}/users/${userId}`);
    },
    [getUserData]
  );

  return {
    getUser,
    userData,
    errorGettingUser,
    loadingUser,
  };
};

export default useUserApi;
