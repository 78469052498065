import React, { lazy, Suspense } from 'react';

const LazyPlanningCreate = lazy(() => import('./PlanningCreate'));

const PlanningCreate = (props) => (
  <Suspense fallback={null}>
    <LazyPlanningCreate {...props} />
  </Suspense>
);

export default PlanningCreate;
