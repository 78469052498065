import React from 'react';
import { Button, Input, Label } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { AiOutlineLeft, AiOutlineRight, AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai';

import styles from './DefaultTableControls.module.scss';

const DefaultTableControls = ({
  previousPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  canNextPage,
  pageCount,
  pageIndex,
  numOfPages,
  pageSize,
  setPageSize,
  wrapperFlexboxBreakpoint,
  numOfRows,
}) => {
  const { pageInput, rowSelectDropdown, tableButtons, controls } = styles;

  return numOfRows > 5 ? (
    <div
      className={`d-flex flex-column flex-${wrapperFlexboxBreakpoint}-row justify-content-between align-items-center w-100 mt-3`}
    >
      <div>
        <Button
          className={classnames(tableButtons, controls, 'me-2')}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          aria-label="First"
        >
          <AiOutlineDoubleLeft />
        </Button>
        <Button
          className={classnames(tableButtons, controls, 'me-2')}
          color="secondary"
          onClick={previousPage}
          disabled={!canPreviousPage}
          aria-label="Previous"
        >
          <AiOutlineLeft />
        </Button>
        <Button
          className={classnames(tableButtons, controls, 'me-2')}
          color="secondary"
          onClick={nextPage}
          disabled={!canNextPage}
          aria-label="Next"
        >
          <AiOutlineRight />
        </Button>
        <Button
          className={classnames(tableButtons, controls)}
          color="secondary"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          aria-label="Last"
        >
          <AiOutlineDoubleRight />
        </Button>
      </div>
      <div
        className={`d-flex flex-column flex-sm-row justify-content-center align-items-center mt-2 mt-${wrapperFlexboxBreakpoint}-0`}
      >
        <div className="d-flex justify-content-center align-items-center mb-2 mb-sm-0">
          <p className="mb-0">
            Page{' '}
            <span className="fw-bold">
              {pageIndex + 1} of {numOfPages}
            </span>
          </p>
          <p className="mb-0 mx-1">|</p>
          <Label className="mb-0 me-2" for="go-to-page-input">
            Go to page:
          </Label>
          <Input
            className={classnames(pageInput, controls, 'me-2')}
            type="number"
            id="go-to-page-input"
            defaultValue={pageIndex + 1}
            max={numOfPages}
            min="1"
            onBlur={(e) => {
              const goTopage = e.target.value ? Number(e.target.value) - 1 : 0;

              gotoPage(goTopage);
            }}
          />
        </div>
        <Input
          className={classnames(rowSelectDropdown, controls)}
          type="select"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Select rows per page"
        >
          {[5, 10, 15, 25, 50, 100].map((updatablePageSize) => (
            <option key={updatablePageSize} value={updatablePageSize}>
              Show {updatablePageSize}
            </option>
          ))}
        </Input>
      </div>
    </div>
  ) : null;
};

DefaultTableControls.defaultProps = {
  wrapperFlexboxBreakpoint: 'lg',
};

DefaultTableControls.propTypes = {
  numOfPages: PropTypes.number.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  wrapperFlexboxBreakpoint: PropTypes.string,
  numOfRows: PropTypes.number.isRequired,
};

export default DefaultTableControls;
