import React, { lazy, Suspense } from 'react';

const LazyAsset = lazy(() => import('./Asset'));

const Asset = (props) => (
  <Suspense fallback={null}>
    <LazyAsset {...props} />
  </Suspense>
);

export default Asset;
