import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import classnames from 'classnames';

import styles from './DefaultTable.module.scss';

const DefaultTable = ({ getTableProps, getTableBodyProps, headerGroups, page, prepareRow }) => {
  const { descSortTableHeaderBorder, ascSortTableHeaderBorder, header } = styles;

  return (
    <Table responsive bordered {...getTableProps()} className="table-hover">
      <thead className={classnames('thead-light', header)}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                className={classnames(
                  {
                    [descSortTableHeaderBorder]: column.canSort && column.isSorted && column.isSortedDesc,
                    [ascSortTableHeaderBorder]: column.canSort && column.isSorted && !column.isSortedDesc,
                  },
                  'align-top'
                )}
              >
                <div>
                  <span {...column.getSortByToggleProps()}>{column.render('Header')} </span>
                </div>
                {column.canFilter && <div>{column.render('Filter')}</div>}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row) => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

DefaultTable.propTypes = {
  getTableProps: PropTypes.func.isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  headerGroups: PropTypes.arrayOf(
    PropTypes.shape({
      getFooterGroupProps: PropTypes.func,
      getHeaderGroupProps: PropTypes.func,
      headers: PropTypes.arrayOf(PropTypes.shape()),
    })
  ).isRequired,
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  prepareRow: PropTypes.func.isRequired,
};

export default DefaultTable;
