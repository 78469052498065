import React, { lazy, Suspense } from 'react';

const LazyAssembly = lazy(() => import('./Assembly'));

const Assembly = (props) => (
  <Suspense fallback={null}>
    <LazyAssembly {...props} />
  </Suspense>
);

export default Assembly;
