import React from 'react';
import classnames from 'classnames';

import Header from 'components/Header/Header';
import ChildrenProp from 'prop-types/ChildrenProp';
import styles from './DefaultTemplate.module.scss';

const DefaultTemplate = ({ children }) => (
  <>
    <Header />
    <main className={classnames(styles.content)}>{children}</main>
  </>
);

DefaultTemplate.propTypes = {
  children: ChildrenProp.isRequired,
};

export default DefaultTemplate;
