import React, { lazy, Suspense } from 'react';

const LazyAssetCreate = lazy(() => import('./AssetCreate'));

const AssetCreate = (props) => (
  <Suspense fallback={null}>
    <LazyAssetCreate {...props} />
  </Suspense>
);

export default AssetCreate;
