import { useCallback, useRef } from 'react';

import useUserLoginModal from './useUserLoginModal';

const useFetch = () => {
  const abortController = useRef(null);
  const { openModal } = useUserLoginModal();

  /**
   * @param  {string} url - full API endpoint path
   * @param  {Object} options - fetch options
   */
  const mamFetch = useCallback(
    async (url, options) => {
      if (!url) {
        throw new Error('A URL was not provided.');
      }

      abortController.current = new AbortController();

      const response = await fetch(url, { signal: abortController.current.signal, ...options });

      if (!response.ok) {
        if (response.status === 401) {
          // On a 401 error open the login modal and set a unique ID.
          // The unique ID is the method plus the response URL and this will be used
          // to determine which on close method to call.
          openModal(`${options.method} ${response.url}`);
        }

        throw response;
      }

      if (
        response.headers.get('Content-Type') === 'text/html' ||
        response.headers.get('Content-Type') === 'application/xhtml+xml' ||
        response.headers.get('Content-Type') === 'text/css'
      ) {
        return response.text();
      }

      return response.json();
    },
    [openModal]
  );

  // Abort active request
  const memoizedAbortActiveRequest = useCallback(() => abortController.current?.abort(), []);

  return {
    mamFetch,
    abortActiveRequest: memoizedAbortActiveRequest,
  };
};

export default useFetch;
