import React from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import { Helmet } from 'react-helmet';

import DefaultTemplate from 'templates/DefaultTemplate/DefaultTemplate';

const Reports = () => {
  return (
    <DefaultTemplate>
      <Helmet>
        <title>MAM: Reports</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Reports</h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <ListGroup>
              <ListGroupItem>
                <a
                  href="https://drive.google.com/drive/folders/17mzoedu-SPBbrHh3aZKdbl2q9OFOZ1tu?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  New Assets in the Catalog
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <a
                  href="https://drive.google.com/drive/folders/1a0kn2q10QsqSJx7KOMNir8p3yDEeYEOP?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Weekly Asset Upload Reports
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <a
                  href="https://drive.google.com/drive/folders/1bAuJTj0RHubWlOYIHHFE3Pz_t66ghODX?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Media Count by Permission Type
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <a
                  href="https://drive.google.com/drive/folders/1onBskZF7ia7vNVOd8CpDoDUapCgAhjfO?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Production Reports
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <a
                  href="https://drive.google.com/drive/folders/1p4Sn6iX4thX83TM5jKPuIAW8nJMufpG_?usp=sharing "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Documentation
                </a>
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </DefaultTemplate>
  );
};

export default Reports;
