import React, { lazy, Suspense } from 'react';

const LazyContractCreate = lazy(() => import('./ContractCreate'));

const ContractCreate = (props) => (
  <Suspense fallback={null}>
    <LazyContractCreate {...props} />
  </Suspense>
);

export default ContractCreate;
