import { useCallback } from 'react';

import { ENDPOINTS } from 'shared/constants/constants';
import useFetchWithState from './useFetchWithState';

const usePlanningApi = () => {
  const {
    mamFetchWithState: getHelpData,
    data: helpData,
    error: errorGettingHelp,
    loading: gettingHelp,
  } = useFetchWithState();

  const getHelp = useCallback(
    (user) => {
      getHelpData(`${ENDPOINTS.EDITORIAL_API}/bsc/docs/userguide-mam${user}.xhtml`);
    },
    [getHelpData]
  );

  return {
    getHelp,
    helpData,
    errorGettingHelp,
    gettingHelp,
  };
};

export default usePlanningApi;
