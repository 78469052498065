import React, { lazy, Suspense } from 'react';

const LazyRendition = lazy(() => import('./Rendition'));

const Rendition = (props) => (
  <Suspense fallback={null}>
    <LazyRendition {...props} />
  </Suspense>
);

export default Rendition;
