import React, { lazy, Suspense } from 'react';

const LazyAssetSubject = lazy(() => import('./AssetSubject'));

const AssetSubject = (props) => (
  <Suspense fallback={null}>
    <LazyAssetSubject {...props} />
  </Suspense>
);

export default AssetSubject;
