import React, { lazy, Suspense } from 'react';

const LazyVendor = lazy(() => import('./Vendor'));

const Vendor = (props) => (
  <Suspense fallback={null}>
    <LazyVendor {...props} />
  </Suspense>
);

export default Vendor;
