import React from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './TextColumnFilter.module.scss';

const TextColumnFilter = ({ column: { filterValue, setFilter, Header } }) => (
  <Input
    value={filterValue || ''}
    onChange={(e) => {
      setFilter(e.target.value || undefined);
    }}
    placeholder="Filter by..."
    className={classnames(styles.input, 'mt-2')}
    data-testid={`filter-${Header.toLowerCase().replace(/\s+/g, '-')}`}
  />
);

TextColumnFilter.defaultProps = {
  column: {},
};

TextColumnFilter.propTypes = {
  column: PropTypes.shape(),
};

export default TextColumnFilter;
