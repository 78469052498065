import { useCallback } from 'react';

import useFetchWithState from './useFetchWithState';
import { EDITORIAL_API } from '../shared/constants/constants';
import useAuthApi from './useAuthApi';

const useWorkflowApi = () => {
  const {
    mamFetchWithState: getProjectsListData,
    data: projectsListData,
    error: errorGettingProjectsList,
    loading: loadingProjectsList,
  } = useFetchWithState();
  const {
    mamFetchWithState: addNewWorkflow,
    data: addWorkflowData,
    error: errorAddingWorkflow,
    loading: addingWorkflow,
  } = useFetchWithState();

  const { getAuthCookies } = useAuthApi();
  const { encodedTicketFromCookie } = getAuthCookies();

  const getProjectsList = useCallback(() => {
    getProjectsListData(`${EDITORIAL_API}/workflow/projects`, {
      headers: {
        Authorization: encodedTicketFromCookie,
      },
    });
  }, [encodedTicketFromCookie, getProjectsListData]);

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   */
  const addWorkflow = useCallback(
    (requestBody) => {
      addNewWorkflow(`${EDITORIAL_API}/workflow/processes`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [addNewWorkflow, encodedTicketFromCookie]
  );

  return {
    getProjectsList,
    projectsListData,
    errorGettingProjectsList,
    loadingProjectsList,
    addWorkflow,
    addWorkflowData,
    errorAddingWorkflow,
    addingWorkflow,
  };
};

export default useWorkflowApi;
