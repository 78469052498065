import { useCallback } from 'react';

import { EDITORIAL_API } from 'shared/constants/constants';
import useFetchWithState from './useFetchWithState';
import useAuthApi from './useAuthApi';

const usePasswordApi = () => {
  const {
    mamFetchWithState: changePasswordData,
    data: changeData,
    error: errorChangingPassword,
    loading: changingPassword,
  } = useFetchWithState();
  const { getAuthCookies } = useAuthApi();
  const { encodedTicketFromCookie } = getAuthCookies();

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   */
  const changePassword = useCallback(
    (requestBody) => {
      changePasswordData(`${EDITORIAL_API}/login/password`, {
        method: 'PATCH',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [changePasswordData, encodedTicketFromCookie]
  );

  return {
    changePassword,
    changeData,
    errorChangingPassword,
    changingPassword,
  };
};

export default usePasswordApi;
