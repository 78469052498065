import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Routes } from 'shared/constants/constants';
import ChildrenProp from 'prop-types/ChildrenProp';
import SpinnerComponent from '../SpinnerComponent/SpinnerComponent';
import spinnerSize1 from '../SpinnerComponent/SpinnerComponent.constants';

const ProtectedRoute = ({ children, isAuthorized, isAuthorizing, redirectTo, captureFromState, ...props }) => {
  const location = useLocation();

  if (isAuthorizing) {
    return <SpinnerComponent size={spinnerSize1} setFullVh />;
  }

  const redirect = captureFromState ? (
    <Redirect to={{ pathname: redirectTo, state: { from: location.pathname } }} />
  ) : (
    <Redirect to={{ pathname: redirectTo }} />
  );

  return <>{isAuthorized ? <Route {...props}>{children}</Route> : redirect}</>;
};

ProtectedRoute.propTypes = {
  children: ChildrenProp.isRequired,
  isAuthorized: PropTypes.bool,
  isAuthorizing: PropTypes.bool,
  redirectTo: PropTypes.string,
  captureFromState: PropTypes.bool,
};

ProtectedRoute.defaultProps = {
  isAuthorized: undefined,
  captureFromState: true,
  isAuthorizing: false,
  redirectTo: Routes.LOGIN.toLink(),
};

export default ProtectedRoute;
