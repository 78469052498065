import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import WIPCreateForm from './WIPCreateForm/WIPCreateForm';
import HelpIcon from '../../components/HelpIcon/HelpIcon';

const WIPCreate = () => {
  const articleTypeId = useLocation().state?.articleTypeId;
  const articleId = useLocation().state?.articleId;
  const wftype = useLocation().state?.wftype;
  const name = useLocation().state?.name;

  return (
    <DefaultTemplate>
      <Helmet>
        <title>MAM: Create Work In Progress</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Create Work In Progress</h1>
              <HelpIcon link="new-Wip" id="newWip" />
            </div>
          </Col>
        </Row>
        <WIPCreateForm articleTypeId={articleTypeId} name={name} articleId={articleId} wftype={wftype} />
      </Container>
    </DefaultTemplate>
  );
};

export default WIPCreate;
