import TextColumnFilter from 'components/TextColumnFilter/TextColumnFilter';

const userDetailTableColumns = [
  {
    Header: 'Group ID',
    accessor: 'groupId',
    disableFilters: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Description',
    accessor: 'description',
    Filter: TextColumnFilter,
  },
];

export default userDetailTableColumns;
