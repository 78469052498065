import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import PageSpinner from 'components/PageSpinner/PageSpinner';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './App.routes';

// TODO: PageSpinner is using a portal and needs to be in this file.
// Are the portals needed for this and modals?
// TODO: Leaving toast container here for now but we will eventually
// need to move it to the default template.
// Currently if moved to the template the toast is not displayed.

const App = () => (
  <>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
    <PageSpinner />
    <ToastContainer autoClose={3000} closeOnClick pauseOnFocusLoss={false} hideProgressBar />
  </>
);

export default App;
