import React, { lazy, Suspense } from 'react';

const LazyVendorCreate = lazy(() => import('./VendorCreate'));

const VendorCreate = (props) => (
  <Suspense fallback={null}>
    <LazyVendorCreate {...props} />
  </Suspense>
);

export default VendorCreate;
