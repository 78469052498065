import React, { lazy, Suspense } from 'react';

const LazyContract = lazy(() => import('./Contract'));

const Contract = (props) => (
  <Suspense fallback={null}>
    <LazyContract {...props} />
  </Suspense>
);

export default Contract;
