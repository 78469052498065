/* eslint-disable react/prop-types */
import React from 'react';
import { diff } from 'deep-object-diff';

export function handleFetchError(response) {
  if (response.status === 404) throw Error('Something went wrong while fetching the API.');
  if (response.status === 400) throw Error('Could not find any results.');
  if (!response.ok) throw Error(response.statusText);

  return response;
}

export function formatDateAndTime(dateString) {
  if (!dateString) return '';

  return new Date(dateString).toLocaleString('en-US', {
    day: '2-digit',
    year: 'numeric',
    month: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
}

export function formatDate(dateString) {
  if (!dateString) return '';

  return new Date(dateString).toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
}

export const formatIsoDateToInputValue = (isoString) => {
  if (!isoString || typeof isoString !== 'string' || !isoString.includes('T')) return '';

  return isoString.split('T')[0];
};

export function removeEmpty(obj = {}) {
  const newObj = {};

  if (typeof obj !== 'object' || Array.isArray(obj) || obj === null) return obj;

  Object.keys(obj).forEach((k) => {
    if ((obj[k] && !Array.isArray(obj[k])) || (Array.isArray(obj[k]) && obj[k].length) || obj[k] === 0) {
      newObj[k] = obj[k];
    }
  });

  return newObj;
}

export const isEmpty = (obj) => !Object.keys(obj).length;

export const formattedAddress = (address = {}) => {
  const { country = {} } = address;
  const street = address.street ? `${address.street}, ` : '';
  const city = address.city ? `${address.city}, ` : '';
  const stateProvince = address.stateProvince ? `${address.stateProvince}` : '';
  const postalCode = address.postalCode ? ` ${address.postalCode}` : '';
  const countryName = country && country.name ? `, ${country.name}` : '';

  let finalString = `${street}${city}${stateProvince}${postalCode}${countryName}`;

  // checks if there is a trailing comma
  finalString = finalString.replace(/,\s*$/, '');
  // checks if the first character is a comma
  finalString = finalString.replace(/^,/, '');

  finalString = finalString.trim();

  return finalString;
};

export const pageCount = (count, maxCount) => {
  if (count < maxCount) return <strong>{count}</strong>;

  return (
    <>
      <strong>{maxCount}</strong> (API max), from <strong>{count}</strong>
    </>
  );
};

export const searchMessage = (types) => (
  <p className="message">Use the form{types ? ` to search for ${types}` : ''}...</p>
);

export const nullifyEmptyValues = (obj = {}) => {
  if (typeof obj !== 'object' || Array.isArray(obj)) return obj;

  const copy = {};

  Object.keys(obj).forEach((key) => {
    // return null for empty arrays
    if ((Array.isArray(obj[key]) && obj[key].length === 0) || obj[key] === '') {
      copy[key] = null;
    } else {
      copy[key] = obj[key] ?? null;
    }
  });

  return copy;
};

export const nullifyEmptyFormValues = (obj = {}) => {
  const copy = {};

  Object.keys(obj).forEach((key) => {
    if ((Array.isArray(obj[key]) && obj[key].length === 0) || !obj[key]) return;

    copy[key] = obj[key] ?? null;
  });

  return copy;
};

export const removeNullValues = (obj) => {
  const resultObject = obj;

  Object.entries(resultObject).forEach(
    ([key, val]) =>
      (val && typeof val === 'object' && removeNullValues(val)) ||
      ((val === null || val === '') && delete resultObject[key])
  );

  return resultObject;
};

export function readableBytes(bytes) {
  if (!bytes) return '';

  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

  return `${(bytes / 1024 ** i).toFixed(2) * 1} ${sizes[i]}`;
}

export function getFileFormat(fileName = '', fileFormatTypes = []) {
  if (!fileName || !fileFormatTypes || !fileFormatTypes.length) return '';

  // Change the file extension to lowercase
  const fileExtension = fileName.split('.').pop().toLowerCase();

  return fileFormatTypes.find((format) => format.extensions && format.extensions.includes(fileExtension));
}

export function normalizeFileFormatExtensionWithName(fileName) {
  // Returns a string including a file name and the lower case extension.

  if (fileName) {
    const fileNameArray = fileName.split('.');

    return `${fileNameArray[0]}.${fileNameArray[1].toLowerCase()}`;
  }

  return '';
}

export const objectDiff = (lhs, rhs) => {
  const deepDiff = diff(lhs, rhs);
  const result = {};

  // Return all key pairs from initial object, only if updated. This will allow to preserve files and entire updated arrays
  Object.keys(deepDiff).forEach((key) => {
    result[key] = rhs[key];
  });

  return result;
};

export const prettifyXml = (sourceXml) => {
  try {
    const xsltProcessor = new XSLTProcessor();
    const xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
    const xsltDoc = new DOMParser().parseFromString(
      [
        // describes how we want to modify the XML - indent everything
        '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
        '  <xsl:strip-space elements="*"/>',
        '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
        '    <xsl:value-of select="normalize-space(.)"/>',
        '  </xsl:template>',
        '  <xsl:template match="node()|@*">',
        '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
        '  </xsl:template>',
        '  <xsl:output indent="yes"/>',
        '</xsl:stylesheet>',
      ].join('\n'),
      'application/xml'
    );

    xsltProcessor.importStylesheet(xsltDoc);

    const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
    const resultXml = new XMLSerializer().serializeToString(resultDoc);

    return resultXml;
  } catch (e) {
    // The styling of the XML was breaking on FireFox.
    // If there is an error above display the XML
    // with no custom styling.
    return sourceXml;
  }
};

export function reportError(error) {
  // eslint-disable-next-line no-console
  console.log(error);
}

export const xmlStringIsValid = (xmlString = '') => {
  const xmlDocumentString = `<?xml version = "1.0"?><body>${xmlString}</body>`;
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlDocumentString, 'application/xml');
  const errors = xmlDoc.getElementsByTagName('parsererror');

  if (errors && errors.length) return false;

  return true;
};

export const arrayToSelectOptions = (arr = []) =>
  arr.map((option) => ({
    label: option,
    value: option,
  }));
