import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { removeEmpty } from '../shared/functions/functions';

const useFormHandler = (emptyFormState, initialFormData = emptyFormState, setFormDataInReduxAction = null) => {
  const [formData, setFormData] = useState(initialFormData);
  const [formError, setFormError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };

  const handleSelectChange = (selected, e) => {
    setFormData({
      ...formData,
      [e.name]: selected,
    });
  };

  const handleVendorChange = (selected, e) => {
    setFormData({
      ...formData,
      [e.name]: selected && selected.value,
    });
  };

  const handleSelectMultipleChange = (selected, e) => {
    setFormData({
      ...formData,
      [e.name]: selected ? selected.map((s) => s.value) : [],
    });
  };

  const handleResetForm = () => {
    setValidationErrors({});
    setFormData(emptyFormState);
    setFormError('');

    if (setFormDataInReduxAction) {
      dispatch(setFormDataInReduxAction(emptyFormState));
    }
  };

  const handleFileChange = ({ target: { files } }) => {
    if (!files.length) return;

    const file = files[0];

    setFormData({
      ...formData,
      attachmentFilename: file.name,
      attachmentFile: file,
      attachmentFileType: file.type,
    });
  };

  const removeEmptyFormValues = useCallback(() => removeEmpty(formData), [formData]);

  /**
   * Check if the form is empty.
   * @return  {boolean} - is the form empty
   */
  const isFormEmpty = () => {
    const formDataWithEmptyValuesRemoved = removeEmptyFormValues();

    if (!Object.keys(formDataWithEmptyValuesRemoved).length) {
      setFormError('The form is empty.');

      return true;
    }

    return false;
  };

  /**
   * Confirm yup validation.
   * @param  {Object} schema - yup validation schema
   * @return  {boolean} - has the form been successfully validated by yup
   */
  const isFormValid = useCallback(
    (schema) => {
      const formDataWithEmptyValuesRemoved = removeEmptyFormValues();
      const castFormData = schema.cast(formDataWithEmptyValuesRemoved);

      const errors = {};

      setValidationErrors({});

      try {
        schema.validateSync(castFormData, { abortEarly: false });

        return true;
      } catch (err) {
        err.inner.forEach((fieldError) => {
          if (fieldError?.message?.errorMessage) {
            const { field, errorMessage } = fieldError.message;

            errors[field] = errorMessage;
          } else {
            errors[fieldError.path] = fieldError.message;
          }
        });

        setValidationErrors(errors);

        return false;
      }
    },
    [removeEmptyFormValues]
  );

  return {
    formData,
    formError,
    validationErrors,
    setValidationErrors,
    setFormData,
    isFormValid,
    removeEmptyFormValues,
    handleInputChange,
    handleSelectChange,
    handleSelectMultipleChange,
    handleResetForm,
    setFormError,
    isFormEmpty,
    handleVendorChange,
    handleFileChange,
  };
};

export default useFormHandler;
