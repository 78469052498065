import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Form, FormGroup, Label, Input, Button, Alert, FormFeedback, FormText } from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import { selectTheme, defineSelectFieldStyles } from 'utils/select-field-utils';
import useFormHandler from 'hooks/useFormHandler';
import { AM_BASE_URL, EDITORIAL_API } from 'shared/constants/constants';
import useWorkflowApi from 'hooks/useWorkflowApi';
import useAuthApi from 'hooks/useAuthApi';
import useUserLoginModal from 'hooks/useUserLoginModal';
import { WIP_CREATE_FORM_INITIAL_STATE, CreateWIPSelectCustomStyles } from './WIPCreateForm.constants';
import WIPCreateSchema from './WIPCreateForm.schema';

const WIPCreateForm = () => {
  const {
    formData,
    formError,
    validationErrors,
    handleInputChange,
    handleSelectChange,
    handleResetForm,
    isFormValid,
    setFormError,
  } = useFormHandler(WIP_CREATE_FORM_INITIAL_STATE);
  const { addWorkflow, addWorkflowData, errorAddingWorkflow, addingWorkflow, getProjectsList, projectsListData } =
    useWorkflowApi();
  const history = useHistory();
  const { userDataFromStore } = useAuthApi();
  const { name } = userDataFromStore;
  const [refinedFormDataForRequest, setRefinedFormDataForRequest] = useState(null);
  const [clusterOptions, setClusterOptions] = useState([]);

  useUserLoginModal({
    onModalClose: () => {
      addWorkflow(refinedFormDataForRequest);
    },
    onCloseActionId: `POST ${EDITORIAL_API}/workflow/processes`,
  });

  // Get workflow workflows list data on mount with articleTypeId, and project cluster list
  useEffect(() => {
    getProjectsList();
  }, [getProjectsList]);

  // if addWIPData exists, send toast feedback to user and redirect to the WIP Detail page
  // for the created WIP id
  useEffect(() => {
    if (addWorkflowData) {
      toast.success('WIP created');
      window.location.href = `${AM_BASE_URL}/workflow/mywork`;
    }
  }, [addWorkflowData, history]);

  useEffect(() => {
    if (projectsListData) {
      const { projects } = projectsListData;

      let CLUSTER_OPTIONS = [];

      CLUSTER_OPTIONS = projects.filter((projectCluster) => projectCluster.entry.name === 'Assembly')[0].entry.clusters;

      setClusterOptions(
        CLUSTER_OPTIONS.map((option) => ({
          value: option,
          label: option,
        }))
      );
    }
  }, [projectsListData]);

  // to handle the different error statuses and respond accordingly, 403 indicates a duplicate
  useEffect(() => {
    if (errorAddingWorkflow) {
      const errorStatus = errorAddingWorkflow?.status;

      if (errorStatus === 403) {
        toast.error({ name: 'Article already in work' });
      } else {
        toast.error('WIP cannot be created');
      }
    }
  }, [errorAddingWorkflow]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isFormValid(WIPCreateSchema)) {
      const refinedFormData = { ...formData, cluster: formData.cluster.value, username: name };

      setRefinedFormDataForRequest(refinedFormData);
      addWorkflow(refinedFormData);
    }
  };

  const onFormReset = (e) => {
    e.preventDefault();
    handleResetForm();
  };

  return (
    <div className="d-flex align-items-start justify-content-center">
      <Form onSubmit={handleSubmit} onReset={onFormReset} data-testid="WIPCreateForm">
        <FormGroup>
          <Label for="wipName">Name: </Label>
          <Input
            id="wipName"
            name="wipName"
            onChange={handleInputChange}
            placeholder="WIP Name..."
            invalid={!!validationErrors?.wipName}
            value={formData.wipName}
          />
          <FormFeedback>{validationErrors.wipName}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label for="project">Project: </Label>
          <Input id="project" name="project" onChange={handleInputChange} value={formData.project} disabled />
        </FormGroup>

        <FormGroup className="mb-3">
          <Label for="cluster">Cluster: </Label>
          <div className="d-flex align-self-center align-items-center justify-content-center">
            <Select
              name="cluster"
              value={formData.cluster}
              onChange={handleSelectChange}
              options={clusterOptions}
              placeholder="Select Cluster..."
              theme={selectTheme}
              styles={defineSelectFieldStyles(CreateWIPSelectCustomStyles)}
            />
          </div>
          {!!validationErrors.cluster && <FormText color="danger">{validationErrors.cluster}</FormText>}
        </FormGroup>

        <FormGroup className="mb-3">
          <Label for="workflow">Workflow: </Label>
          <Input id="workflow" name="workflow" onChange={handleInputChange} value="Assembly" disabled />
        </FormGroup>

        <div className="d-flex justify-content-center align-items-center">
          <Button type="submit" color="primary" className="mr-2" disabled={addingWorkflow}>
            {addingWorkflow ? 'Creating...' : 'Create'}
          </Button>
          <Button type="reset" color="link" disabled={addingWorkflow}>
            Reset
          </Button>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-3">
          <Alert
            color="danger"
            className={classnames(alert, 'mb-0')}
            isOpen={!!formError}
            toggle={() => setFormError('')}
            fade={false}
          >
            {formError}
          </Alert>
        </div>
      </Form>
    </div>
  );
};

export default WIPCreateForm;
