import React from 'react';
// import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ErrorBoundary } from 'react-error-boundary';
import { createRoot } from 'react-dom/client';

import ErrorFallback from 'components/ErrorFallback/ErrorFallback';
import App from './App';
import { store, persistor } from './store/store';
import './index.scss';

const root = createRoot(document.getElementById('root'));

root.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);
